import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import logoWhite from "src/assets/images/vaporware-logo-white.svg"
import logoBlue from "src/assets/images/vaporware-logo-dark.svg"

const Logo = ({ className, isBlue }) => (
  <Link className={className} to="/">
    {isBlue ? (
      <img src={logoBlue} alt="Vaporware" />
    ) : (
      <img src={logoWhite} alt="Vaporware" />
    )}
  </Link>
)

Logo.defaultProps = {
  className: "",
  isBlue: false,
}

Logo.propTypes = {
  className: PropTypes.string,
  isBlue: PropTypes.bool,
}

export default Logo
