import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ lang, title, image, description, meta, canonical}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    >
      {canonical && <link rel="canonical" href={canonical} />}
      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "@id": "https://www.vaporware.net",
          "name": "Vaporware®",
          "description": "Custom software development and design",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "400 W. Morgan St",
            "addressLocality": "Raleigh",
            "addressRegion": "NC",
            "postalCode": "27603",
            "addressCountry": "US"
          },
          "url": "https://www.vaporware.net",
          "logo": "https://www.vaporware.net/vw-logo.svg",
          "sameAs": [
            "https://github.com/vaporware",
            "https://twitter.com/vpware",
            "https://www.facebook.com/vaporware/",
            "https://www.linkedin.com/company/vaporware"
          ],
          "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+1-919-827-4061",
            "contactType": "customer support",
            "areaServed": "US"
            }]
        }
      `}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  description: `Innovative solutions for your greatest challenges`,
  image: ``,
  lang: `en`,
  meta: [],
  title: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
